import React, { useEffect, useState } from "react"
//import axios, { Axios } from 'axios'
import axios from 'axios'
import { useRef } from "react";

export default function Courses() {


    const [buttonClick, setButtonClick] = useState(false)
    const [buttonClick2, setButtonClick2] = useState(false)
    const [buttonClick3, setButtonClick3] = useState(false)
    const [buttonClick4, setButtonClick4] = useState(false)
    const [buttonClick5, setButtonClick5] = useState(false)
    const [buttonClick6, setButtonClick6] = useState(false)

    const [completedRepeating, setCompletedRepeating] = useState(0)
    const [completedRepeating2, setCompletedRepeating2] = useState(0)
    const [completedRepeating3, setCompletedRepeating3] = useState(0)
    const [completedRepeating4, setCompletedRepeating4] = useState(0)
    const [completedRepeating5, setCompletedRepeating5] = useState(0)

    axios.defaults.withCredentials = true

    const [percentage, setPercentage] = useState(0)
    const [percentage2, setPercentage2] = useState(0)
    const [percentage3, setPercentage3] = useState(0)
    const [percentage4, setPercentage4] = useState(0)
    const [percentage5, setPercentage5] = useState(0)

    const [percentageRepeat, setPercentageRepeat] = useState(0)
    const [percentageRepeat2, setPercentageRepeat2] = useState(0)
    const [percentageRepeat3, setPercentageRepeat3] = useState(0)
    const [percentageRepeat4, setPercentageRepeat4] = useState(0)
    const [percentageRepeat5, setPercentageRepeat5] = useState(0)

    const generalScore =() => {

        /*axios.get('${process.env.REACT_APP_BACKEND}/generalScore', {
        }).then((response) => {

            alert(`the score is ${response.data.amount}`)
            if (response.data.amount){
                setScore(response.data.amount)
            } 
            
        }).error((err) => {
            console.log(err.message)
            //alert(err.message)
        })*/

        /*axios.get('${process.env.REACT_APP_BACKEND}/generalScore').then((response) => {
            //setPost(response.data);
            if (response.data.amount){
                setScore(response.data.amount)
            } 
        });*/
        
    }

    const [isPaid, setIsPaid] = useState(0)
    const [isPaid2, setIsPaid2] = useState(0)
    const [isPaid3, setIsPaid3] = useState(0)
    const [isPaid4, setIsPaid4] = useState(0)
    const [isPaid5, setIsPaid5] = useState(0)

    const [completedTwenty, setCompletedTwenty] = useState(0)
    const [completedTwenty2, setCompletedTwenty2] = useState(0)
    const [completedTwenty3, setCompletedTwenty3] = useState(0)
    const [completedTwenty4, setCompletedTwenty4] = useState(0)
    const [completedTwenty5, setCompletedTwenty5] = useState(0)

 const is_paid =() => {
    axios.get(`${process.env.REACT_APP_BACKEND}/is_paid`).then((response) => {
        //setPost(response.data);
        //alert(JSON.stringify(response.data))
            //console.log(response.data.paid)
            if(response.data.paid === 1){
                setIsPaid(response.data.paid)
            }else if (response.data.paid === 0){
                setIsPaid(response.data.paid)
                is_completed_twenty()
            }
    });
 }

 const is_paidHSK2 =() => {
    axios.get(`${process.env.REACT_APP_BACKEND}/is_paid?hsk=${'_hsk2'}`).then((response) => {
        //setPost(response.data);
        //alert(JSON.stringify(response.data))
            //console.log(response.data.paid)
            if(response.data.paid === 1){
                setIsPaid2(response.data.paid)
            }else if (response.data.paid === 0){
                setIsPaid2(response.data.paid)
                is_completed_twenty2()
            }
    });
 }

 const is_paidHSK3 =() => {
    axios.get(`${process.env.REACT_APP_BACKEND}/is_paid?hsk=${'_hsk3'}`).then((response) => {
        //setPost(response.data);
        //alert(JSON.stringify(response.data))
            //console.log(response.data.paid)
            if(response.data.paid === 1){
                setIsPaid3(response.data.paid)
            }else if (response.data.paid === 0){
                setIsPaid3(response.data.paid)
                is_completed_twenty3()
            }
    });
 }

 const is_paidHSK4 =() => {
    axios.get(`${process.env.REACT_APP_BACKEND}/is_paid?hsk=${'_hsk4'}`).then((response) => {
        //setPost(response.data);
        //alert(JSON.stringify(response.data))
            //console.log(response.data.paid)
            if(response.data.paid === 1){
                setIsPaid4(response.data.paid)
            }else if (response.data.paid === 0){
                setIsPaid4(response.data.paid)
                is_completed_twenty4()
            }
    });
 }

 
 const is_paidHSK5 =() => {
    axios.get(`${process.env.REACT_APP_BACKEND}/is_paid?hsk=${'_hsk5'}`).then((response) => {
        //setPost(response.data);
        //alert(JSON.stringify(response.data))
            //console.log(response.data.paid)
            if(response.data.paid === 1){
                setIsPaid5(response.data.paid)
            }else if (response.data.paid === 0){
                setIsPaid5(response.data.paid)
                is_completed_twenty5()
            }
    });
 }


 
 const is_completed_twenty =() => {
    axios.get(`${process.env.REACT_APP_BACKEND}/is_completed_twenty`).then((response) => {
        //setPost(response.data);
        //alert(JSON.stringify(response.data))
        if (response.data.completed){
            setCompletedTwenty(response.data.completed)
        } 
    });
 }

 const is_completed_twenty2 =() => {
    axios.get(`${process.env.REACT_APP_BACKEND}/is_completed_twenty?hsk=${'_hsk2'}`).then((response) => {
        //setPost(response.data);
        //alert(JSON.stringify(response.data))
        if (response.data.completed){
            setCompletedTwenty2(response.data.completed)
        } 
    });
 }

 const is_completed_twenty3 =() => {
    axios.get(`${process.env.REACT_APP_BACKEND}/is_completed_twenty?hsk=${'_hsk3'}`).then((response) => {
        //setPost(response.data);
        //alert(JSON.stringify(response.data))
        if (response.data.completed){
            setCompletedTwenty3(response.data.completed)
        } 
    });
 }
 const is_completed_twenty4 =() => {
    axios.get(`${process.env.REACT_APP_BACKEND}/is_completed_twenty?hsk=${'_hsk4'}`).then((response) => {
        //setPost(response.data);
        //alert(JSON.stringify(response.data))
        if (response.data.completed){
            setCompletedTwenty4(response.data.completed)
        } 
    });
 }
 const is_completed_twenty5 =() => {
    axios.get(`${process.env.REACT_APP_BACKEND}/is_completed_twenty?hsk=${'_hsk5'}`).then((response) => {
        //setPost(response.data);
        //alert(JSON.stringify(response.data))
        if (response.data.completed){
            setCompletedTwenty5(response.data.completed)
        } 
    });
 }

    useEffect(() => {
        generalScore()
        is_paid()
        is_paidHSK2()
        is_paidHSK3()
        is_paidHSK4()
        is_paidHSK5()
        const fetchData = async () => {
            await countScore();
        };

        fetchData();

        const fetchDataHSK2 = async () => {
            await countScore2();
        };
        fetchDataHSK2();

        const fetchDataHSK3 = async () => {
            await countScore3();
        };
        fetchDataHSK3();

        const fetchDataHSK4 = async () => {
            await countScore4();
        };

        fetchDataHSK4();

        const fetchDataHSK5 = async () => {
            await countScore5();
        };
        fetchDataHSK5();

        //amountOfCharactersFunction()
        repeatedOver()

        repeatedOver2()
        repeatedOver3()
        repeatedOver4()
        repeatedOver5()

        const fetchData2 = async () => {
            await isMessage();
        };

        fetchData2();

        const fetchData3 = async () => {
            await countScoreRepeat();
        };
        fetchData3();

        const fetchData3HSK2 = async () => {
            await countScoreRepeat2();
        };
        fetchData3HSK2();

        const fetchData3HSK3 = async () => {
            await countScoreRepeat3();
        };

        fetchData3HSK3();

        const fetchData3HSK4 = async () => {
            await countScoreRepeat4();
        };

        fetchData3HSK4();


        const fetchData3HSK5 = async () => {
            await countScoreRepeat5();
        };

        fetchData3HSK5();

    }, [])

    const countScore = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND}/countScore`);
            if (response.data.score !== undefined) {
                setPercentage(response.data.score);
                //console.log('the result is', response.data.score);
            }
        } catch (error) {
            console.error('Error fetching the score:', error);
        }
    };

    const countScore2 = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND}/countScore?hsk=${'_hsk2'}`);
            if (response.data.score !== undefined) {
                setPercentage2(response.data.score);
                //console.log('the result is', response.data.score);
            }
        } catch (error) {
            console.error('Error fetching the score:', error);
        }
    };

    const countScore3 = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND}/countScore?hsk=${'_hsk3'}`);
            if (response.data.score !== undefined) {
                setPercentage3(response.data.score);
                //console.log('the result is', response.data.score);
            }
        } catch (error) {
            console.error('Error fetching the score:', error);
        }
    };

    const countScore4 = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND}/countScore?hsk=${'_hsk4'}`);
            if (response.data.score !== undefined) {
                setPercentage4(response.data.score);
                //console.log('the result is', response.data.score);
            }
        } catch (error) {
            console.error('Error fetching the score:', error);
        }
    };

    const countScore5 = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND}/countScore?hsk=${'_hsk5'}`);
            if (response.data.score !== undefined) {
                setPercentage5(response.data.score);
                //console.log('the result is', response.data.score);
            }
        } catch (error) {
            console.error('Error fetching the score:', error);
        }
    };

    const countScoreRepeat = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND}/countScoreRepeat`);
            if (response.data.score !== undefined) {
                //console.log(response.data.score)
                setPercentageRepeat(response.data.score);
                //console.log('the result is', response.data.score);
            }
        } catch (error) {
            console.error('Error fetching the score:', error);
        }
    };

    const countScoreRepeat2 = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND}/countScoreRepeat?hsk=${'_hsk2'}`);
            if (response.data.score !== undefined) {
                //console.log(response.data.score)
                setPercentageRepeat2(response.data.score);
                //console.log('the result is', response.data.score);
            }
        } catch (error) {
            console.error('Error fetching the score:', error);
        }
    };

    const countScoreRepeat3 = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND}/countScoreRepeat?hsk=${'_hsk3'}`);
            if (response.data.score !== undefined) {
                //console.log(response.data.score)
                setPercentageRepeat3(response.data.score);
                //console.log('the result is', response.data.score);
            }
        } catch (error) {
            console.error('Error fetching the score:', error);
        }
    };

    const countScoreRepeat4 = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND}/countScoreRepeat?hsk=${'_hsk4'}`);
            if (response.data.score !== undefined) {
                //console.log(response.data.score)
                setPercentageRepeat4(response.data.score);
                //console.log('the result is', response.data.score);
            }
        } catch (error) {
            console.error('Error fetching the score:', error);
        }
    };

    const countScoreRepeat5 = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND}/countScoreRepeat?hsk=${'_hsk5'}`);
            if (response.data.score !== undefined) {
                //console.log(response.data.score)
                setPercentageRepeat5(response.data.score);
                //console.log('the result is', response.data.score);
            }
        } catch (error) {
            console.error('Error fetching the score:', error);
        }
    };

    const [hsk2Message, setHSK2Message] = useState('')
    const [hsk3Message, setHSK3Message] = useState('')
    const [hsk4Message, setHSK4Message] = useState('')
    const [hsk5Message, setHSK5Message] = useState('')
    const [hsk6Message, setHSK6Message] = useState('')
    const [hsk, setHSK] = useState(0)

    const isMessage = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND}/isMessageForHSK`);
                setHSK2Message(response.data.hsk2);
                setHSK3Message(response.data.hsk3)
                setHSK4Message(response.data.hsk4)
                setHSK5Message(response.data.hsk5)
                setHSK6Message(response.data.hsk6)
        } catch (error) {
            console.error('Error fetching the score:', error);
        }
    };

    const sendEmailQuestion = async (hsk, answer) => {
        try {
            await axios.post(`${process.env.REACT_APP_BACKEND}/sendEmailQuestion`, 
                {
                    hsk: hsk,
                    sendEmail: answer
                })
        } catch (error) {
            console.error('Error fetching the score:', error);
        }
    };

      const repeatedOver =() => {

        axios.get(`${process.env.REACT_APP_BACKEND}/isRepeatingOver`).then((response) => {
            //setPost(response.data);
            if (response.data.completed){
                setCompletedRepeating(response.data.completed)
                //console.log('the result is', response.data.completed)
            } 
        });
        
    }

    const repeatedOver2 =() => {

        axios.get(`${process.env.REACT_APP_BACKEND}/isRepeatingOver?hsk=${'_hsk2'}`).then((response) => {
            //setPost(response.data);
            if (response.data.completed){
                setCompletedRepeating2(response.data.completed)
                //console.log('the result is', response.data.completed)
            } 
        });
        
    }

    const repeatedOver3 =() => {

        axios.get(`${process.env.REACT_APP_BACKEND}/isRepeatingOver?hsk=${'_hsk3'}`).then((response) => {
            //setPost(response.data);
            if (response.data.completed){
                setCompletedRepeating3(response.data.completed)
                //console.log('the result is', response.data.completed)
            } 
        });
        
    }

    const repeatedOver4 =() => {

        axios.get(`${process.env.REACT_APP_BACKEND}/isRepeatingOver?hsk=${'_hsk4'}`).then((response) => {
            //setPost(response.data);
            if (response.data.completed){
                setCompletedRepeating4(response.data.completed)
                //console.log('the result is', response.data.completed)
            } 
        });
        
    }

    const repeatedOver5 =() => {

        axios.get(`${process.env.REACT_APP_BACKEND}/isRepeatingOver?hsk=${'_hsk5'}`).then((response) => {
            //setPost(response.data);
            if (response.data.completed){
                setCompletedRepeating5(response.data.completed)
                //console.log('the result is', response.data.completed)
            } 
        });
        
    }

    const [isOpen, setIsOpen] = useState(0);
    const [isOpenQuiz, setIsOpenQuiz] = useState(0);
    const [askingBox, setAskingBox] = useState(0);
    const [askingBoxQuiz, setAskingBoxQuiz] = useState(0);

    const [isOpen2, setIsOpen2] = useState(0);
    const [isOpenQuiz2, setIsOpenQuiz2] = useState(0);
    const [askingBox2, setAskingBox2] = useState(0);
    const [askingBoxQuiz2, setAskingBoxQuiz2] = useState(0);

    const [isOpen3, setIsOpen3] = useState(0);
    const [isOpenQuiz3, setIsOpenQuiz3] = useState(0);
    const [askingBox3, setAskingBox3] = useState(0);
    const [askingBoxQuiz3, setAskingBoxQuiz3] = useState(0);

    const [isOpen4, setIsOpen4] = useState(0);
    const [isOpenQuiz4, setIsOpenQuiz4] = useState(0);
    const [askingBox4, setAskingBox4] = useState(0);
    const [askingBoxQuiz4, setAskingBoxQuiz4] = useState(0);

    const [isOpen5, setIsOpen5] = useState(0);
    const [isOpenQuiz5, setIsOpenQuiz5] = useState(0);
    const [askingBox5, setAskingBox5] = useState(0);
    const [askingBoxQuiz5, setAskingBoxQuiz5] = useState(0);

    const [askingBoxSendMessage, setAskingBoxSendMessage] = useState(0);

    const handleClickAlert = () => {
        setIsOpen(1)
    }

    const handleClickAlert2 = () => {
        setIsOpen2(1)
    }

    const handleClickAlert3 = () => {
        setIsOpen3(1)
    }

    const handleClickAlert4 = () => {
        setIsOpen4(1)
    }

    const handleClickAlert5 = () => {
        setIsOpen5(1)
    }
    
    const handleClickAlertRemoveQuestionsRepeat = () => {
        setAskingBox(1)
    }

    const handleClickAlertRemoveQuestionsRepeat2 = () => {
        setAskingBox2(1)
    }

    const handleClickAlertRemoveQuestionsRepeat3 = () => {
        setAskingBox3(1)
    }

    const handleClickAlertRemoveQuestionsRepeat4 = () => {
        setAskingBox4(1)
    }

    const handleClickAlertRemoveQuestionsRepeat5 = () => {
        setAskingBox5(1)
    }

    const handleClickAlertRemoveQuestions = () => {
        setAskingBoxQuiz(1)
    }

    const handleClickAlertRemoveQuestions2 = () => {
        setAskingBoxQuiz2(1)
    }

    const handleClickAlertRemoveQuestions3 = () => {
        setAskingBoxQuiz3(1)
    }


    const handleClickAlertRemoveQuestions4 = () => {
        setAskingBoxQuiz4(1)
    }


    const handleClickAlertRemoveQuestions5 = () => {
        setAskingBoxQuiz5(1)
    }

    const removeChatFunctionOff = () => {
        setAskingBox(0)
      };

      const removeChatFunctionOff2 = () => {
        setAskingBox2(0)
      };

      const removeChatFunctionOff3 = () => {
        setAskingBox3(0)
      };

      const removeChatFunctionOff4 = () => {
        setAskingBox4(0)
      };

      const removeChatFunctionOff5 = () => {
        setAskingBox5(0)
      };

      const sendMessageInfoYes = async (hsk) => {
        if(hsk === 2){
            setHSK2Message('exists')
        }else if(hsk === 3){
            setHSK3Message('exists')
        }else if(hsk === 4){
            setHSK4Message('exists')
        }else if(hsk === 5){
            setHSK5Message('exists')
        } else if(hsk === 6){
            setHSK6Message('exists')
        }
        setAskingBoxSendMessage(0)
        await sendEmailQuestion(hsk, 'Yes')
      };

      const sendMessageInfoNot = async (hsk) => {
        if(hsk === 2){
            setHSK2Message('exists')
        }else if(hsk === 3){
            setHSK3Message('exists')
        }else if(hsk === 4){
            setHSK4Message('exists')
        }else if(hsk === 5){
            setHSK5Message('exists')
        } else if(hsk === 6){
            setHSK6Message('exists')
        }
        setAskingBoxSendMessage(0)
        await sendEmailQuestion(hsk, 'No')
      };

      const removeChatFunctionOffQuiz = () => {
        setAskingBoxQuiz(0)
      };

      const removeChatFunctionOffQuiz2 = () => {
        setAskingBoxQuiz2(0)
      };

      const removeChatFunctionOffQuiz3 = () => {
        setAskingBoxQuiz3(0)
      };

      const removeChatFunctionOffQuiz4 = () => {
        setAskingBoxQuiz4(0)
      };

      const removeChatFunctionOffQuiz5 = () => {
        setAskingBoxQuiz5(0)
      };

      const restartQuizRepeat =() => {

        axios.get(`${process.env.REACT_APP_BACKEND}/restartQuizRepeat`).then((response) => {
            if (response.data.restarted){
                repeatedOver()
                countScoreRepeat()
                //console.log(response.data.restarted)
            } 
        });
        setAskingBox(0)
    }

    const restartQuizRepeat2 =() => {

        axios.get(`${process.env.REACT_APP_BACKEND}/restartQuizRepeat?hsk=${'_hsk2'}`).then((response) => {
            if (response.data.restarted){
                repeatedOver2()
                countScoreRepeat2()
                //console.log(response.data.restarted)
            } 
        });
        setAskingBox2(0)
    }

    const restartQuizRepeat3 =() => {

        axios.get(`${process.env.REACT_APP_BACKEND}/restartQuizRepeat?hsk=${'_hsk3'}`).then((response) => {
            if (response.data.restarted){
                repeatedOver3()
                countScoreRepeat3()
                //console.log(response.data.restarted)
            } 
        });
        setAskingBox3(0)
    }

    const restartQuizRepeat4 =() => {

        axios.get(`${process.env.REACT_APP_BACKEND}/restartQuizRepeat?hsk=${'_hsk4'}`).then((response) => {
            if (response.data.restarted){
                repeatedOver4()
                countScoreRepeat4()
                //console.log(response.data.restarted)
            } 
        });
        setAskingBox4(0)
    }

    const restartQuizRepeat5 =() => {

        axios.get(`${process.env.REACT_APP_BACKEND}/restartQuizRepeat?hsk=${'_hsk5'}`).then((response) => {
            if (response.data.restarted){
                repeatedOver5()
                countScoreRepeat5()
                //console.log(response.data.restarted)
            } 
        });
        setAskingBox5(0)
    }

    const restartQuiz =() => {
        axios.get(`${process.env.REACT_APP_BACKEND}/restartQuiz`).then((response) => {
            if (response.data.restarted){
                is_paid()
                countScore()
                countScoreRepeat()
                repeatedOver()
            } 
        });
        setAskingBoxQuiz(0)
    }

    const restartQuiz2 =() => {
        axios.get(`${process.env.REACT_APP_BACKEND}/restartQuiz?hsk=${'_hsk2'}`).then((response) => {
            if (response.data.restarted){
                is_paidHSK2()
                countScore2()
                countScoreRepeat2()
                repeatedOver2()
            } 
        });
        setAskingBoxQuiz2(0)
    }

    const restartQuiz3 =() => {
        axios.get(`${process.env.REACT_APP_BACKEND}/restartQuiz?hsk=${'_hsk3'}`).then((response) => {
            if (response.data.restarted){
                is_paidHSK3()
                countScore3()
                countScoreRepeat3()
                repeatedOver3()
            } 
        });
        setAskingBoxQuiz3(0)
    }

    const restartQuiz4 =() => {
        axios.get(`${process.env.REACT_APP_BACKEND}/restartQuiz?hsk=${'_hsk4'}`).then((response) => {
            if (response.data.restarted){
                is_paidHSK4()
                countScore4()
                countScoreRepeat4()
                repeatedOver4()
            } 
        });
        setAskingBoxQuiz4(0)
    }

    const restartQuiz5 =() => {
        axios.get(`${process.env.REACT_APP_BACKEND}/restartQuiz?hsk=${'_hsk5'}`).then((response) => {
            if (response.data.restarted){
                is_paidHSK5()
                countScore5()
                countScoreRepeat5()
                repeatedOver5()
            } 
        });
        setAskingBoxQuiz5(0)
    }


    //console.log('maxQuestions is', maxQuestions)
    //console.log('score is', score)
    //const percentage = score / maxQuestions
    //const percentage = 1

    const documentRef = useRef(document); 

    useEffect(() => {
        const handleClick = (event) => {
            // Handling first block
            const block1 = document.getElementById('repeatBlockButtons');
            const open1 = document.getElementById('id-three-dots');
            if (open1 && open1.contains(event.target)) {
                setButtonClick(true);
                setIsOpen(0);
            } else if (block1 && !block1.contains(event.target)) {
                setButtonClick(false);
            }

            // Handling second block
            const block2 = document.getElementById('repeatBlockButtons2');
            const open2 = document.getElementById('id-three-dots2');
            if (open2 && open2.contains(event.target)) {
                setButtonClick2(true);
                setIsOpen2(0);
            } else if (block2 && !block2.contains(event.target)) {
                setButtonClick2(false);
            }

            // Handling third block
            const block3 = document.getElementById('repeatBlockButtons3');
            const open3 = document.getElementById('id-three-dots3');
            if (open3 && open3.contains(event.target)) {
                setButtonClick3(true);
                setIsOpen3(0);
            } else if (block3 && !block3.contains(event.target)) {
                setButtonClick3(false);
            }

            const block4 = document.getElementById('repeatBlockButtons4');
            const open4 = document.getElementById('id-three-dots4');
            if (open4 && open4.contains(event.target)) {
                setButtonClick4(true);
                setIsOpen4(0);
            } else if (block4 && !block4.contains(event.target)) {
                setButtonClick4(false);
            }

            const block5 = document.getElementById('repeatBlockButtons5');
            const open5 = document.getElementById('id-three-dots5');
            if (open5 && open5.contains(event.target)) {
                setButtonClick5(true);
                setIsOpen5(0);
            } else if (block5 && !block5.contains(event.target)) {
                setButtonClick5(false);
            }

            const block6 = document.getElementById('repeatBlockButtons6');
            const open6 = document.getElementById('id-three-dots6');
            if (open6 && open6.contains(event.target)) {
                setButtonClick6(true);
            } else if (block6 && !block6.contains(event.target)) {
                setButtonClick6(false);
            }

            const block7 = document.getElementById('alertDivBlackQuiz');
            const open7 = document.getElementById('dotCompletedQuiz');
            if (open7 && open7.contains(event.target)) {
                setIsOpenQuiz(1);
            } else if (block7 && !block7.contains(event.target)) {
                setIsOpenQuiz(0);
            }

            const block7HSK2 = document.getElementById('alertDivBlackQuiz2');
            const open7HSK2 = document.getElementById('dotCompletedQuiz2');
            if (open7HSK2 && open7HSK2.contains(event.target)) {
                setIsOpenQuiz2(1);
            } else if (block7HSK2 && !block7HSK2.contains(event.target)) {
                setIsOpenQuiz2(0);
            }

            const block7HSK3 = document.getElementById('alertDivBlackQuiz3');
            const open7HSK3 = document.getElementById('dotCompletedQuiz3');
            if (open7HSK3 && open7HSK3.contains(event.target)) {
                setIsOpenQuiz3(1);
            } else if (block7HSK3 && !block7HSK3.contains(event.target)) {
                setIsOpenQuiz3(0);
            }


            const block7HSK4 = document.getElementById('alertDivBlackQuiz4');
            const open7HSK4 = document.getElementById('dotCompletedQuiz4');
            if (open7HSK4 && open7HSK4.contains(event.target)) {
                setIsOpenQuiz4(1);
            } else if (block7HSK4 && !block7HSK4.contains(event.target)) {
                setIsOpenQuiz4(0);
            }

            const block7HSK5 = document.getElementById('alertDivBlackQuiz5');
            const open7HSK5 = document.getElementById('dotCompletedQuiz5');
            if (open7HSK5 && open7HSK5.contains(event.target)) {
                setIsOpenQuiz5(1);
            } else if (block7HSK5 && !block7HSK5.contains(event.target)) {
                setIsOpenQuiz5(0);
            }


            const block8 = document.getElementById('askingBoxSendMessage');
            const open8 = document.getElementById('askQuestion');
            if (open8 && open8.contains(event.target)) {
                setAskingBoxSendMessage(1)
                setHSK(2)
            } else if (block8 && !block8.contains(event.target)) {
                setHSK(null)
                setAskingBoxSendMessage(0)
            }

            const block9 = document.getElementById('askingBoxSendMessage3');
            const open9 = document.getElementById('askQuestion3');
            if (open9 && open9.contains(event.target)) {
                setAskingBoxSendMessage(1)
                setHSK(3)
            } else if (block9 && !block9.contains(event.target)) {
                setHSK(null)
                setAskingBoxSendMessage(0)
            }

            const block10 = document.getElementById('askingBoxSendMessage4');
            const open10 = document.getElementById('askQuestion4');
            if (open10 && open10.contains(event.target)) {
                setAskingBoxSendMessage(1)
                setHSK(4)
            } else if (block10 && !block10.contains(event.target)) {
                setHSK(null)
                setAskingBoxSendMessage(0)
            }

            const block11 = document.getElementById('askingBoxSendMessage5');
            const open11 = document.getElementById('askQuestion5');
            if (open11 && open11.contains(event.target)) {
                setAskingBoxSendMessage(1)
                setHSK(5)
            } else if (block11 && !block11.contains(event.target)) {
                setHSK(null)
                setAskingBoxSendMessage(0)
            }

            const block12 = document.getElementById('askingBoxSendMessage6');
            const open12 = document.getElementById('askQuestion6');
            if (open12 && open12.contains(event.target)) {
                setAskingBoxSendMessage(1)
                setHSK(6)
            } else if (block12 && !block12.contains(event.target)) {
                setHSK(null)
                setAskingBoxSendMessage(0)
            }

        };
        documentRef.current.addEventListener('click', handleClick);

        return () => {
            documentRef.current.removeEventListener('click', handleClick);
        };
    }, []);
    
    /*documentRef.current.addEventListener('click', (event) => {
        const block = document.getElementById('repeatBlockButtons');
        const open = document.getElementById('id-three-dots');
        console.log('block is', block)
        if(open.contains(event.target)) {
            setButtonClick(true)
            setIsOpen(0)
        } else if(!block.contains(event.target)) {
            setButtonClick(false)
        }
    })

    documentRef.current.addEventListener('click', (event) => {
        const block = document.getElementById('repeatBlockButtons2');
        const open = document.getElementById('id-three-dots2');
        //console.log(block)
        if(open.contains(event.target)) {
            setButtonClick2(true)
        } else if(!block.contains(event.target)) {
            setButtonClick2(false)
        }
    })

    documentRef.current.addEventListener('click', (event) => {
        const block = document.getElementById('repeatBlockButtons3');
        const open = document.getElementById('id-three-dots3');
        //console.log(block)
        if(open.contains(event.target)) {
            setButtonClick3(true)
        } else if(!block.contains(event.target)) {
            setButtonClick3(false)
        }
    })

    documentRef.current.addEventListener('click', (event) => {
        const block = document.getElementById('repeatBlockButtons4');
        const open = document.getElementById('id-three-dots4');
        //console.log(block)
        if(open.contains(event.target)) {
            setButtonClick4(true)
        } else if(!block.contains(event.target)) {
            setButtonClick4(false)
        }
    })

    documentRef.current.addEventListener('click', (event) => {
        const block = document.getElementById('repeatBlockButtons5');
        const open = document.getElementById('id-three-dots5');
        //console.log(block)
        if(open.contains(event.target)) {
            setButtonClick5(true)
        } else if(!block.contains(event.target)) {
            setButtonClick5(false)
        }
    })

    documentRef.current.addEventListener('click', (event) => {
        const block = document.getElementById('repeatBlockButtons6');
        const open = document.getElementById('id-three-dots6');
        //console.log(block)
        if(open.contains(event.target)) {
            setButtonClick6(true)
        } else if(!block.contains(event.target)) {
            setButtonClick6(false)
        }
    })


    documentRef.current.addEventListener('click', (event) => {
        const block = document.getElementById('alertDivBlackQuiz');
        const open = document.getElementById('dotCompletedQuiz');
        //console.log(block)
        if(open.contains(event.target)) {
            setIsOpenQuiz(1)
        } else if(!block.contains(event.target)) {
            setIsOpenQuiz(0)
        }
    })*/

    return (
        <div className="dotsBlock"> 
        <div className="dots">
            <div className="verticalBlocks">
                <a href={completedTwenty === 5 ? "/paying" : (percentage === 1 ? undefined : "/quiz")} className="hskDot">
                    <span className={percentage === 1 ? "dotCompletedQuiz" : "dot"} id={percentage === 1 ? "dotCompletedQuiz" : ""}>HSK 1</span>
                </a>
                <div className={isOpenQuiz === 1 ? (completedTwenty === 5 ? 'notDisplayed' : 'alertDivBlackQuiz') : 'notDisplayed' } id="alertDivBlackQuiz">
  
                <div className="alertDiv-content">
                The quiz is over, click the button in the extra menu if you want to restart
                </div>
                </div>

                <button className="threeDots" id="id-three-dots">
                    <div className="blackDot"></div>
                    <div className="blackDot"></div>
                    <div className="blackDot"></div>
                </button>

                <div className="repeatBlockHorizontal">
                {
                    buttonClick ? 
                    <div className="repeatBlockButtons">
                            
                        <div className={isOpen === 1 ? 'alertDivBlack' : 'notDisplayed' }>
            
                            <div className="alertDiv-content">
                            Repeat circle is over, click repeat again if you want to restart
                            </div>

                        </div>

                        <div className="repeatBlockButtons2" id="repeatBlockButtons">
                        <a href={completedRepeating === 1 || completedRepeating === 3 ? undefined : "/repeatQuiz"} className={completedRepeating === 1 || completedRepeating === 3 ? "repeatButton2" : "repeatButton"} onClick={completedRepeating === 1 ? handleClickAlert : null}>
                            Repeat
                        </a>
                        <svg xmlns="http://www.w3.org/2000/svg" className={completedRepeating === 1 ? "checkDone" : "notDisplayed"} width="25px" height="25px" viewBox="0 0 24 24" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd" d="M12 21C16.9706 21 21 16.9706 21 12C21 10.1666 20.4518 8.46124 19.5103 7.03891L12.355 14.9893C11.6624 15.7589 10.4968 15.8726 9.66844 15.2513L6.4 12.8C5.95817 12.4686 5.86863 11.8418 6.2 11.4C6.53137 10.9582 7.15817 10.8686 7.6 11.2L10.8684 13.6513L18.214 5.48955C16.5986 3.94717 14.4099 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" fill="green"/>
                        </svg>
                        <div className={percentageRepeat != 0 ? "progressRepeat" : "notDisplayed"}>
                            <div className="progressBoxRepeat" style={{"width": `${percentageRepeat * 170}px`}}></div>
                        </div>
                        <a href="/list" className="wordsList">
                            Words
                        </a>     
                        </div>  

                        <div className={completedRepeating === 1 ? "repeatWordSvgAbsolute2" : "notDisplayed"} onClick={completedRepeating === 1 ? handleClickAlertRemoveQuestionsRepeat : null}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 16 16">
                        <path fill="#000000" d="M5.23331,0.493645 C6.8801,-0.113331 8.6808,-0.161915 10.3579,0.355379 C11.5179,0.713177 12.5743,1.32796 13.4526,2.14597 L14.2929,1.30564 C14.9229,0.675676 16,1.12184 16,2.01275 L16,6.00002 L12.0127,6.00002 C11.1218,6.00002 10.6757,4.92288 11.3056,4.29291 L12.0372,3.56137 C11.389,2.97184 10.6156,2.52782 9.76845,2.26653 C8.5106,1.87856 7.16008,1.915 5.92498,2.37023 C4.68989,2.82547 3.63877,3.67423 2.93361,4.78573 C2.22844,5.89723 1.90836,7.20978 2.02268,8.52112 C2.13701,9.83246 2.6794,11.0698 3.56627,12.0425 C4.45315,13.0152 5.63528,13.6693 6.93052,13.9039 C8.22576,14.1385 9.56221,13.9407 10.7339,13.3409 C11.9057,12.7412 12.8476,11.7727 13.4147,10.5848 C13.6526,10.0864 14.2495,9.8752 14.748,10.1131 C15.2464,10.351 15.4575,10.948 15.2196,11.4464 C14.4635,13.0302 13.2076,14.3215 11.6453,15.1213 C10.0829,15.921 8.30101,16.1847 6.57402,15.8719 C4.84704,15.559 3.27086,14.687 2.08836,13.39 C0.905861,12.0931 0.182675,10.4433 0.0302394,8.69483 C-0.122195,6.94637 0.304581,5.1963 1.2448,3.7143 C2.18503,2.2323 3.58652,1.10062 5.23331,0.493645 Z"/>
                        </svg>   
                        </div>    
                        <div className={percentage === 1 ? "repeatWordSvgAbsolute" : (completedTwenty === 5 ? "repeatWordSvgAbsolute" : "notDisplayed")} onClick={percentage === 1 || completedTwenty === 5 ? handleClickAlertRemoveQuestions : null}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 16 16">
                        <path fill="#000000" d="M5.23331,0.493645 C6.8801,-0.113331 8.6808,-0.161915 10.3579,0.355379 C11.5179,0.713177 12.5743,1.32796 13.4526,2.14597 L14.2929,1.30564 C14.9229,0.675676 16,1.12184 16,2.01275 L16,6.00002 L12.0127,6.00002 C11.1218,6.00002 10.6757,4.92288 11.3056,4.29291 L12.0372,3.56137 C11.389,2.97184 10.6156,2.52782 9.76845,2.26653 C8.5106,1.87856 7.16008,1.915 5.92498,2.37023 C4.68989,2.82547 3.63877,3.67423 2.93361,4.78573 C2.22844,5.89723 1.90836,7.20978 2.02268,8.52112 C2.13701,9.83246 2.6794,11.0698 3.56627,12.0425 C4.45315,13.0152 5.63528,13.6693 6.93052,13.9039 C8.22576,14.1385 9.56221,13.9407 10.7339,13.3409 C11.9057,12.7412 12.8476,11.7727 13.4147,10.5848 C13.6526,10.0864 14.2495,9.8752 14.748,10.1131 C15.2464,10.351 15.4575,10.948 15.2196,11.4464 C14.4635,13.0302 13.2076,14.3215 11.6453,15.1213 C10.0829,15.921 8.30101,16.1847 6.57402,15.8719 C4.84704,15.559 3.27086,14.687 2.08836,13.39 C0.905861,12.0931 0.182675,10.4433 0.0302394,8.69483 C-0.122195,6.94637 0.304581,5.1963 1.2448,3.7143 C2.18503,2.2323 3.58652,1.10062 5.23331,0.493645 Z"/>
                        </svg>   
                        </div>   

                    </div> 
                    : 
                    <div className="emptyBox"></div>
                }

                <div className="progress">
                            <div className="progressBox" style={{"width": `${percentage * 190}px`}}></div>
                </div>
                </div>

                <div className="repeatBlockResponsive">
                {
                    buttonClick ? 
                    <div className="verticalBlocksResponsiveRepeatBlock">
                    <div className="repeatBlockButtons">
                    <div className={isOpen2 === 1 ? 'alertDivBlack' : 'notDisplayed' }>
  
                    <div className="alertDiv-content">
                    Repeat circle is over, click repeat again if you want to restart
                    </div>
                    </div>
                        <div className="repeatBlockButtons2" id="repeatBlockButtons">
                        <a href={completedRepeating2 === 1 || completedRepeating2 === 3 ? undefined : "/repeatQuiz"} className={completedRepeating2 === 1 || completedRepeating2 === 3 ? "repeatButton2" : "repeatButton"} onClick={completedRepeating2 === 1 ? handleClickAlert2 : null}>
                            Repeat
                        </a>
                        <svg xmlns="http://www.w3.org/2000/svg" className={completedRepeating2 === 1 ? "checkDone" : "notDisplayed"} width="25px" height="25px" viewBox="0 0 24 24" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd" d="M12 21C16.9706 21 21 16.9706 21 12C21 10.1666 20.4518 8.46124 19.5103 7.03891L12.355 14.9893C11.6624 15.7589 10.4968 15.8726 9.66844 15.2513L6.4 12.8C5.95817 12.4686 5.86863 11.8418 6.2 11.4C6.53137 10.9582 7.15817 10.8686 7.6 11.2L10.8684 13.6513L18.214 5.48955C16.5986 3.94717 14.4099 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" fill="green"/>
                        </svg>
                        <div className={percentageRepeat2 != 0 ? "progressRepeat" : "notDisplayed"}>
                            <div className="progressBoxRepeat" style={{"width": `${percentageRepeat2 * 170}px`}}></div>
                        </div>
                        <a href="/list" className="wordsList">
                            Words
                        </a>     
                        </div>  
                        <div className={completedRepeating2 === 1 ? "repeatWordSvgAbsolute2" : "notDisplayed"} onClick={completedRepeating2 === 1 ? handleClickAlertRemoveQuestionsRepeat2 : null}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 16 16">
                        <path fill="#000000" d="M5.23331,0.493645 C6.8801,-0.113331 8.6808,-0.161915 10.3579,0.355379 C11.5179,0.713177 12.5743,1.32796 13.4526,2.14597 L14.2929,1.30564 C14.9229,0.675676 16,1.12184 16,2.01275 L16,6.00002 L12.0127,6.00002 C11.1218,6.00002 10.6757,4.92288 11.3056,4.29291 L12.0372,3.56137 C11.389,2.97184 10.6156,2.52782 9.76845,2.26653 C8.5106,1.87856 7.16008,1.915 5.92498,2.37023 C4.68989,2.82547 3.63877,3.67423 2.93361,4.78573 C2.22844,5.89723 1.90836,7.20978 2.02268,8.52112 C2.13701,9.83246 2.6794,11.0698 3.56627,12.0425 C4.45315,13.0152 5.63528,13.6693 6.93052,13.9039 C8.22576,14.1385 9.56221,13.9407 10.7339,13.3409 C11.9057,12.7412 12.8476,11.7727 13.4147,10.5848 C13.6526,10.0864 14.2495,9.8752 14.748,10.1131 C15.2464,10.351 15.4575,10.948 15.2196,11.4464 C14.4635,13.0302 13.2076,14.3215 11.6453,15.1213 C10.0829,15.921 8.30101,16.1847 6.57402,15.8719 C4.84704,15.559 3.27086,14.687 2.08836,13.39 C0.905861,12.0931 0.182675,10.4433 0.0302394,8.69483 C-0.122195,6.94637 0.304581,5.1963 1.2448,3.7143 C2.18503,2.2323 3.58652,1.10062 5.23331,0.493645 Z"/>
                        </svg>   
                        </div>    
                        <div className={percentage2 === 1 ? "repeatWordSvgAbsolute" : (completedTwenty2 === 5 ? "repeatWordSvgAbsolute" : "notDisplayed")} onClick={percentage2 === 1 || completedTwenty2 === 5 ? handleClickAlertRemoveQuestions2 : null}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 16 16">
                        <path fill="#000000" d="M5.23331,0.493645 C6.8801,-0.113331 8.6808,-0.161915 10.3579,0.355379 C11.5179,0.713177 12.5743,1.32796 13.4526,2.14597 L14.2929,1.30564 C14.9229,0.675676 16,1.12184 16,2.01275 L16,6.00002 L12.0127,6.00002 C11.1218,6.00002 10.6757,4.92288 11.3056,4.29291 L12.0372,3.56137 C11.389,2.97184 10.6156,2.52782 9.76845,2.26653 C8.5106,1.87856 7.16008,1.915 5.92498,2.37023 C4.68989,2.82547 3.63877,3.67423 2.93361,4.78573 C2.22844,5.89723 1.90836,7.20978 2.02268,8.52112 C2.13701,9.83246 2.6794,11.0698 3.56627,12.0425 C4.45315,13.0152 5.63528,13.6693 6.93052,13.9039 C8.22576,14.1385 9.56221,13.9407 10.7339,13.3409 C11.9057,12.7412 12.8476,11.7727 13.4147,10.5848 C13.6526,10.0864 14.2495,9.8752 14.748,10.1131 C15.2464,10.351 15.4575,10.948 15.2196,11.4464 C14.4635,13.0302 13.2076,14.3215 11.6453,15.1213 C10.0829,15.921 8.30101,16.1847 6.57402,15.8719 C4.84704,15.559 3.27086,14.687 2.08836,13.39 C0.905861,12.0931 0.182675,10.4433 0.0302394,8.69483 C-0.122195,6.94637 0.304581,5.1963 1.2448,3.7143 C2.18503,2.2323 3.58652,1.10062 5.23331,0.493645 Z"/>
                        </svg>   
                        </div>                     
                    </div> 
                    <div className="progress">
                            <div className="progressBox" style={{"width": `${percentage * 190}px`}}></div>
                    </div>
                    </div>
                    : 
                    <div></div>
                }

                </div>

            </div>















            <img src="../images/liana3.png" alt="#" className="liana"/>

            <div className="verticalBlocks">
                <a href={completedTwenty2 === 5 ? "/payingHSK2" : (percentage2 === 1 ? undefined : "/quizHSK2")} className="hskDot">
                    <span className={percentage2 === 1 ? "dotCompletedQuiz" : "dot"} id={percentage2 === 1 ? "dotCompletedQuiz2" : ""}>HSK 2</span>
                </a>

                <div className={isOpenQuiz2 === 1 ? (completedTwenty2 === 5 ? 'notDisplayed' : 'alertDivBlackQuiz') : 'notDisplayed' } id="alertDivBlackQuiz2">
  
                <div className="alertDiv-content">
                The quiz is over, click the button in the extra menu if you want to restart
                </div>
                </div>

                <button className="threeDots" id="id-three-dots2">
                    <div className="blackDot"></div>
                    <div className="blackDot"></div>
                    <div className="blackDot"></div>
                </button>

                <div className="repeatBlockHorizontal">
                {
                    buttonClick2 ? 
                    <div className="repeatBlockButtons">

                    <div className={isOpen2 === 1 ? 'alertDivBlack' : 'notDisplayed' }>
                    
                        <div className="alertDiv-content">
                        Repeat circle is over, click repeat again if you want to restart
                        </div>

                    </div>

                        <div className="repeatBlockButtons2" id="repeatBlockButtons2">
                        <a href={completedRepeating2 === 1 || completedRepeating2 === 3 ? undefined : "/repeatQuizHSK2"} className={completedRepeating2 === 1 || completedRepeating2 === 3 ? "repeatButton2" : "repeatButton"} onClick={completedRepeating2 === 1 ? handleClickAlert2 : null}>
                            Repeat
                        </a>

                        <svg xmlns="http://www.w3.org/2000/svg" className={completedRepeating2 === 1 ? "checkDone" : "notDisplayed"} width="25px" height="25px" viewBox="0 0 24 24" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd" d="M12 21C16.9706 21 21 16.9706 21 12C21 10.1666 20.4518 8.46124 19.5103 7.03891L12.355 14.9893C11.6624 15.7589 10.4968 15.8726 9.66844 15.2513L6.4 12.8C5.95817 12.4686 5.86863 11.8418 6.2 11.4C6.53137 10.9582 7.15817 10.8686 7.6 11.2L10.8684 13.6513L18.214 5.48955C16.5986 3.94717 14.4099 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" fill="green"/>
                        </svg>
                        <div className={percentageRepeat2 != 0 ? "progressRepeat" : "notDisplayed"}>
                            <div className="progressBoxRepeat" style={{"width": `${percentageRepeat2 * 170}px`}}></div>
                        </div>

                        <a href="/listHSK2" className="wordsList2">
                            Words
                        </a>     
                        </div>   

                        <div className={completedRepeating2 === 1 ? "repeatWordSvgAbsolute2" : "notDisplayed"} onClick={completedRepeating2 === 1 ? handleClickAlertRemoveQuestionsRepeat2 : null}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 16 16">
                        <path fill="#000000" d="M5.23331,0.493645 C6.8801,-0.113331 8.6808,-0.161915 10.3579,0.355379 C11.5179,0.713177 12.5743,1.32796 13.4526,2.14597 L14.2929,1.30564 C14.9229,0.675676 16,1.12184 16,2.01275 L16,6.00002 L12.0127,6.00002 C11.1218,6.00002 10.6757,4.92288 11.3056,4.29291 L12.0372,3.56137 C11.389,2.97184 10.6156,2.52782 9.76845,2.26653 C8.5106,1.87856 7.16008,1.915 5.92498,2.37023 C4.68989,2.82547 3.63877,3.67423 2.93361,4.78573 C2.22844,5.89723 1.90836,7.20978 2.02268,8.52112 C2.13701,9.83246 2.6794,11.0698 3.56627,12.0425 C4.45315,13.0152 5.63528,13.6693 6.93052,13.9039 C8.22576,14.1385 9.56221,13.9407 10.7339,13.3409 C11.9057,12.7412 12.8476,11.7727 13.4147,10.5848 C13.6526,10.0864 14.2495,9.8752 14.748,10.1131 C15.2464,10.351 15.4575,10.948 15.2196,11.4464 C14.4635,13.0302 13.2076,14.3215 11.6453,15.1213 C10.0829,15.921 8.30101,16.1847 6.57402,15.8719 C4.84704,15.559 3.27086,14.687 2.08836,13.39 C0.905861,12.0931 0.182675,10.4433 0.0302394,8.69483 C-0.122195,6.94637 0.304581,5.1963 1.2448,3.7143 C2.18503,2.2323 3.58652,1.10062 5.23331,0.493645 Z"/>
                        </svg>   
                        </div>    
                        <div className={percentage2 === 1 ? "repeatWordSvgAbsolute" : (completedTwenty2 === 5 ? "repeatWordSvgAbsolute" : "notDisplayed")} onClick={percentage2 === 1 || completedTwenty2 === 5 ? handleClickAlertRemoveQuestions2 : null}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 16 16">
                        <path fill="#000000" d="M5.23331,0.493645 C6.8801,-0.113331 8.6808,-0.161915 10.3579,0.355379 C11.5179,0.713177 12.5743,1.32796 13.4526,2.14597 L14.2929,1.30564 C14.9229,0.675676 16,1.12184 16,2.01275 L16,6.00002 L12.0127,6.00002 C11.1218,6.00002 10.6757,4.92288 11.3056,4.29291 L12.0372,3.56137 C11.389,2.97184 10.6156,2.52782 9.76845,2.26653 C8.5106,1.87856 7.16008,1.915 5.92498,2.37023 C4.68989,2.82547 3.63877,3.67423 2.93361,4.78573 C2.22844,5.89723 1.90836,7.20978 2.02268,8.52112 C2.13701,9.83246 2.6794,11.0698 3.56627,12.0425 C4.45315,13.0152 5.63528,13.6693 6.93052,13.9039 C8.22576,14.1385 9.56221,13.9407 10.7339,13.3409 C11.9057,12.7412 12.8476,11.7727 13.4147,10.5848 C13.6526,10.0864 14.2495,9.8752 14.748,10.1131 C15.2464,10.351 15.4575,10.948 15.2196,11.4464 C14.4635,13.0302 13.2076,14.3215 11.6453,15.1213 C10.0829,15.921 8.30101,16.1847 6.57402,15.8719 C4.84704,15.559 3.27086,14.687 2.08836,13.39 C0.905861,12.0931 0.182675,10.4433 0.0302394,8.69483 C-0.122195,6.94637 0.304581,5.1963 1.2448,3.7143 C2.18503,2.2323 3.58652,1.10062 5.23331,0.493645 Z"/>
                        </svg>   
                        </div>      

                    </div> 
                    : 
                    <div className="emptyBox"></div>
                }

                <div className="progress">
                    <div className="progressBox" style={{"width": `${percentage2 * 190}px`}}></div>
                </div>
                </div>

                <div className="repeatBlockResponsive">
                {
                    buttonClick2 ? 
                    <div className="verticalBlocksResponsiveRepeatBlock">
                    <div className="repeatBlockButtons">
                    <div className={isOpen2 === 1 ? 'alertDivBlack' : 'notDisplayed' }>
  
                    <div className="alertDiv-content">
                    Repeat circle is over, click repeat again if you want to restart
                    </div>
                    </div>
                        <div className="repeatBlockButtons2" id="repeatBlockButtons2">
                        <a href={completedRepeating2 === 1 || completedRepeating2 === 3 ? undefined : "/repeatQuizHSK2"} className={completedRepeating2 === 1 || completedRepeating2 === 3 ? "repeatButton2" : "repeatButton"} onClick={completedRepeating2 === 1 ? handleClickAlert2 : null}>
                            Repeat
                        </a>
                        <svg xmlns="http://www.w3.org/2000/svg" className={completedRepeating2 === 1 ? "checkDone" : "notDisplayed"} width="25px" height="25px" viewBox="0 0 24 24" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd" d="M12 21C16.9706 21 21 16.9706 21 12C21 10.1666 20.4518 8.46124 19.5103 7.03891L12.355 14.9893C11.6624 15.7589 10.4968 15.8726 9.66844 15.2513L6.4 12.8C5.95817 12.4686 5.86863 11.8418 6.2 11.4C6.53137 10.9582 7.15817 10.8686 7.6 11.2L10.8684 13.6513L18.214 5.48955C16.5986 3.94717 14.4099 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" fill="green"/>
                        </svg>
                        <div className={percentageRepeat2 != 0 ? "progressRepeat" : "notDisplayed"}>
                            <div className="progressBoxRepeat" style={{"width": `${percentageRepeat * 170}px`}}></div>
                        </div>
                        <a href="/listHSK2" className="wordsList">
                            Words
                        </a>     
                        </div>  
                        <div className={completedRepeating2 === 1 ? "repeatWordSvgAbsolute2" : "notDisplayed"} onClick={completedRepeating2 === 1 ? handleClickAlertRemoveQuestionsRepeat2 : null}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 16 16">
                        <path fill="#000000" d="M5.23331,0.493645 C6.8801,-0.113331 8.6808,-0.161915 10.3579,0.355379 C11.5179,0.713177 12.5743,1.32796 13.4526,2.14597 L14.2929,1.30564 C14.9229,0.675676 16,1.12184 16,2.01275 L16,6.00002 L12.0127,6.00002 C11.1218,6.00002 10.6757,4.92288 11.3056,4.29291 L12.0372,3.56137 C11.389,2.97184 10.6156,2.52782 9.76845,2.26653 C8.5106,1.87856 7.16008,1.915 5.92498,2.37023 C4.68989,2.82547 3.63877,3.67423 2.93361,4.78573 C2.22844,5.89723 1.90836,7.20978 2.02268,8.52112 C2.13701,9.83246 2.6794,11.0698 3.56627,12.0425 C4.45315,13.0152 5.63528,13.6693 6.93052,13.9039 C8.22576,14.1385 9.56221,13.9407 10.7339,13.3409 C11.9057,12.7412 12.8476,11.7727 13.4147,10.5848 C13.6526,10.0864 14.2495,9.8752 14.748,10.1131 C15.2464,10.351 15.4575,10.948 15.2196,11.4464 C14.4635,13.0302 13.2076,14.3215 11.6453,15.1213 C10.0829,15.921 8.30101,16.1847 6.57402,15.8719 C4.84704,15.559 3.27086,14.687 2.08836,13.39 C0.905861,12.0931 0.182675,10.4433 0.0302394,8.69483 C-0.122195,6.94637 0.304581,5.1963 1.2448,3.7143 C2.18503,2.2323 3.58652,1.10062 5.23331,0.493645 Z"/>
                        </svg>   
                        </div>    
                        <div className={percentage2 === 1 ? "repeatWordSvgAbsolute" : (completedTwenty2 === 5 ? "repeatWordSvgAbsolute" : "notDisplayed")} onClick={percentage2 === 1 || completedTwenty2 === 5 ? handleClickAlertRemoveQuestions2 : null}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 16 16">
                        <path fill="#000000" d="M5.23331,0.493645 C6.8801,-0.113331 8.6808,-0.161915 10.3579,0.355379 C11.5179,0.713177 12.5743,1.32796 13.4526,2.14597 L14.2929,1.30564 C14.9229,0.675676 16,1.12184 16,2.01275 L16,6.00002 L12.0127,6.00002 C11.1218,6.00002 10.6757,4.92288 11.3056,4.29291 L12.0372,3.56137 C11.389,2.97184 10.6156,2.52782 9.76845,2.26653 C8.5106,1.87856 7.16008,1.915 5.92498,2.37023 C4.68989,2.82547 3.63877,3.67423 2.93361,4.78573 C2.22844,5.89723 1.90836,7.20978 2.02268,8.52112 C2.13701,9.83246 2.6794,11.0698 3.56627,12.0425 C4.45315,13.0152 5.63528,13.6693 6.93052,13.9039 C8.22576,14.1385 9.56221,13.9407 10.7339,13.3409 C11.9057,12.7412 12.8476,11.7727 13.4147,10.5848 C13.6526,10.0864 14.2495,9.8752 14.748,10.1131 C15.2464,10.351 15.4575,10.948 15.2196,11.4464 C14.4635,13.0302 13.2076,14.3215 11.6453,15.1213 C10.0829,15.921 8.30101,16.1847 6.57402,15.8719 C4.84704,15.559 3.27086,14.687 2.08836,13.39 C0.905861,12.0931 0.182675,10.4433 0.0302394,8.69483 C-0.122195,6.94637 0.304581,5.1963 1.2448,3.7143 C2.18503,2.2323 3.58652,1.10062 5.23331,0.493645 Z"/>
                        </svg>   
                        </div>                     
                    </div> 
                    <div className="progress">
                            <div className="progressBox" style={{"width": `${percentage * 190}px`}}></div>
                    </div>
                    </div>
                    : 
                    <div></div>
                }

                </div>
            </div>



            <img src="../images/liana3.png" alt="#" className="liana"/>

            <div className="verticalBlocks">
                <a href={completedTwenty3 === 5 ? "/payingHSK3" : (percentage3 === 1 ? undefined : "/quizHSK3")} className="hskDot">
                    <span className={percentage3 === 1 ? "dotCompletedQuiz" : "dot"} id={percentage3 === 1 ? "dotCompletedQuiz3" : ""}>HSK 3</span>
                </a>

                <div className={isOpenQuiz3 === 1 ? (completedTwenty3 === 5 ? 'notDisplayed' : 'alertDivBlackQuiz') : 'notDisplayed' } id="alertDivBlackQuiz3">
  
                <div className="alertDiv-content">
                The quiz is over, click the button in the extra menu if you want to restart
                </div>
                </div>

                <button className="threeDots" id="id-three-dots3">
                    <div className="blackDot"></div>
                    <div className="blackDot"></div>
                    <div className="blackDot"></div>
                </button>

                <div className="repeatBlockHorizontal">
                {
                    buttonClick3 ? 
                    <div className="repeatBlockButtons">

                    <div className={isOpen3 === 1 ? 'alertDivBlack' : 'notDisplayed' }>
                    
                        <div className="alertDiv-content">
                        Repeat circle is over, click repeat again if you want to restart
                        </div>

                    </div>

                        <div className="repeatBlockButtons2" id="repeatBlockButtons3">
                        <a href={completedRepeating3 === 1 || completedRepeating3 === 3 ? undefined : "/repeatQuizHSK3"} className={completedRepeating3 === 1 || completedRepeating3 === 3 ? "repeatButton2" : "repeatButton"} onClick={completedRepeating3 === 1 ? handleClickAlert3 : null}>
                            Repeat
                        </a>

                        <svg xmlns="http://www.w3.org/2000/svg" className={completedRepeating3 === 1 ? "checkDone" : "notDisplayed"} width="25px" height="25px" viewBox="0 0 24 24" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd" d="M12 21C16.9706 21 21 16.9706 21 12C21 10.1666 20.4518 8.46124 19.5103 7.03891L12.355 14.9893C11.6624 15.7589 10.4968 15.8726 9.66844 15.2513L6.4 12.8C5.95817 12.4686 5.86863 11.8418 6.2 11.4C6.53137 10.9582 7.15817 10.8686 7.6 11.2L10.8684 13.6513L18.214 5.48955C16.5986 3.94717 14.4099 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" fill="green"/>
                        </svg>
                        <div className={percentageRepeat3 != 0 ? "progressRepeat" : "notDisplayed"}>
                            <div className="progressBoxRepeat" style={{"width": `${percentageRepeat3 * 170}px`}}></div>
                        </div>

                        <a href={isPaid3 ? "/listHSK3" : "/listHSK3Words?id=1"} className="wordsList2">
                            Words
                        </a>     
                        </div>   

                        <div className={completedRepeating3 === 1 ? "repeatWordSvgAbsolute2" : "notDisplayed"} onClick={completedRepeating3 === 1 ? handleClickAlertRemoveQuestionsRepeat3 : null}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 16 16">
                        <path fill="#000000" d="M5.23331,0.493645 C6.8801,-0.113331 8.6808,-0.161915 10.3579,0.355379 C11.5179,0.713177 12.5743,1.32796 13.4526,2.14597 L14.2929,1.30564 C14.9229,0.675676 16,1.12184 16,2.01275 L16,6.00002 L12.0127,6.00002 C11.1218,6.00002 10.6757,4.92288 11.3056,4.29291 L12.0372,3.56137 C11.389,2.97184 10.6156,2.52782 9.76845,2.26653 C8.5106,1.87856 7.16008,1.915 5.92498,2.37023 C4.68989,2.82547 3.63877,3.67423 2.93361,4.78573 C2.22844,5.89723 1.90836,7.20978 2.02268,8.52112 C2.13701,9.83246 2.6794,11.0698 3.56627,12.0425 C4.45315,13.0152 5.63528,13.6693 6.93052,13.9039 C8.22576,14.1385 9.56221,13.9407 10.7339,13.3409 C11.9057,12.7412 12.8476,11.7727 13.4147,10.5848 C13.6526,10.0864 14.2495,9.8752 14.748,10.1131 C15.2464,10.351 15.4575,10.948 15.2196,11.4464 C14.4635,13.0302 13.2076,14.3215 11.6453,15.1213 C10.0829,15.921 8.30101,16.1847 6.57402,15.8719 C4.84704,15.559 3.27086,14.687 2.08836,13.39 C0.905861,12.0931 0.182675,10.4433 0.0302394,8.69483 C-0.122195,6.94637 0.304581,5.1963 1.2448,3.7143 C2.18503,2.2323 3.58652,1.10062 5.23331,0.493645 Z"/>
                        </svg>   
                        </div>    
                        <div className={percentage3 === 1 ? "repeatWordSvgAbsolute" : (completedTwenty3 === 5 ? "repeatWordSvgAbsolute" : "notDisplayed")} onClick={percentage3 === 1 || completedTwenty3 === 5 ? handleClickAlertRemoveQuestions3 : null}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 16 16">
                        <path fill="#000000" d="M5.23331,0.493645 C6.8801,-0.113331 8.6808,-0.161915 10.3579,0.355379 C11.5179,0.713177 12.5743,1.32796 13.4526,2.14597 L14.2929,1.30564 C14.9229,0.675676 16,1.12184 16,2.01275 L16,6.00002 L12.0127,6.00002 C11.1218,6.00002 10.6757,4.92288 11.3056,4.29291 L12.0372,3.56137 C11.389,2.97184 10.6156,2.52782 9.76845,2.26653 C8.5106,1.87856 7.16008,1.915 5.92498,2.37023 C4.68989,2.82547 3.63877,3.67423 2.93361,4.78573 C2.22844,5.89723 1.90836,7.20978 2.02268,8.52112 C2.13701,9.83246 2.6794,11.0698 3.56627,12.0425 C4.45315,13.0152 5.63528,13.6693 6.93052,13.9039 C8.22576,14.1385 9.56221,13.9407 10.7339,13.3409 C11.9057,12.7412 12.8476,11.7727 13.4147,10.5848 C13.6526,10.0864 14.2495,9.8752 14.748,10.1131 C15.2464,10.351 15.4575,10.948 15.2196,11.4464 C14.4635,13.0302 13.2076,14.3215 11.6453,15.1213 C10.0829,15.921 8.30101,16.1847 6.57402,15.8719 C4.84704,15.559 3.27086,14.687 2.08836,13.39 C0.905861,12.0931 0.182675,10.4433 0.0302394,8.69483 C-0.122195,6.94637 0.304581,5.1963 1.2448,3.7143 C2.18503,2.2323 3.58652,1.10062 5.23331,0.493645 Z"/>
                        </svg>   
                        </div>      

                    </div> 
                    : 
                    <div className="emptyBox"></div>
                }

                <div className="progress">
                    <div className="progressBox" style={{"width": `${percentage3 * 190}px`}}></div>
                </div>
                </div>

                <div className="repeatBlockResponsive">
                {
                    buttonClick3 ? 
                    <div className="verticalBlocksResponsiveRepeatBlock">
                    <div className="repeatBlockButtons">
                    <div className={isOpen3 === 1 ? 'alertDivBlack' : 'notDisplayed' }>
  
                    <div className="alertDiv-content">
                    Repeat circle is over, click repeat again if you want to restart
                    </div>
                    </div>
                        <div className="repeatBlockButtons2" id="repeatBlockButtons3">
                        <a href={completedRepeating3 === 1 || completedRepeating3 === 3 ? undefined : "/repeatQuizHSK3"} className={completedRepeating3 === 1 || completedRepeating3 === 3 ? "repeatButton2" : "repeatButton"} onClick={completedRepeating3 === 1 ? handleClickAlert3 : null}>
                            Repeat
                        </a>
                        <svg xmlns="http://www.w3.org/2000/svg" className={completedRepeating3 === 1 ? "checkDone" : "notDisplayed"} width="25px" height="25px" viewBox="0 0 24 24" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd" d="M12 21C16.9706 21 21 16.9706 21 12C21 10.1666 20.4518 8.46124 19.5103 7.03891L12.355 14.9893C11.6624 15.7589 10.4968 15.8726 9.66844 15.2513L6.4 12.8C5.95817 12.4686 5.86863 11.8418 6.2 11.4C6.53137 10.9582 7.15817 10.8686 7.6 11.2L10.8684 13.6513L18.214 5.48955C16.5986 3.94717 14.4099 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" fill="green"/>
                        </svg>
                        <div className={percentageRepeat3 != 0 ? "progressRepeat" : "notDisplayed"}>
                            <div className="progressBoxRepeat" style={{"width": `${percentageRepeat3 * 170}px`}}></div>
                        </div>
                        <a href={isPaid3 ? "/listHSK3" : "/listHSK3Words?id=1"} className="wordsList">
                            Words
                        </a>     
                        </div>  
                        <div className={completedRepeating3 === 1 ? "repeatWordSvgAbsolute2" : "notDisplayed"} onClick={completedRepeating3 === 1 ? handleClickAlertRemoveQuestionsRepeat3 : null}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 16 16">
                        <path fill="#000000" d="M5.23331,0.493645 C6.8801,-0.113331 8.6808,-0.161915 10.3579,0.355379 C11.5179,0.713177 12.5743,1.32796 13.4526,2.14597 L14.2929,1.30564 C14.9229,0.675676 16,1.12184 16,2.01275 L16,6.00002 L12.0127,6.00002 C11.1218,6.00002 10.6757,4.92288 11.3056,4.29291 L12.0372,3.56137 C11.389,2.97184 10.6156,2.52782 9.76845,2.26653 C8.5106,1.87856 7.16008,1.915 5.92498,2.37023 C4.68989,2.82547 3.63877,3.67423 2.93361,4.78573 C2.22844,5.89723 1.90836,7.20978 2.02268,8.52112 C2.13701,9.83246 2.6794,11.0698 3.56627,12.0425 C4.45315,13.0152 5.63528,13.6693 6.93052,13.9039 C8.22576,14.1385 9.56221,13.9407 10.7339,13.3409 C11.9057,12.7412 12.8476,11.7727 13.4147,10.5848 C13.6526,10.0864 14.2495,9.8752 14.748,10.1131 C15.2464,10.351 15.4575,10.948 15.2196,11.4464 C14.4635,13.0302 13.2076,14.3215 11.6453,15.1213 C10.0829,15.921 8.30101,16.1847 6.57402,15.8719 C4.84704,15.559 3.27086,14.687 2.08836,13.39 C0.905861,12.0931 0.182675,10.4433 0.0302394,8.69483 C-0.122195,6.94637 0.304581,5.1963 1.2448,3.7143 C2.18503,2.2323 3.58652,1.10062 5.23331,0.493645 Z"/>
                        </svg>   
                        </div>    
                        <div className={percentage3 === 1 ? "repeatWordSvgAbsolute" : (completedTwenty3 === 5 ? "repeatWordSvgAbsolute" : "notDisplayed")} onClick={percentage3 === 1 || completedTwenty3 === 5 ? handleClickAlertRemoveQuestions3 : null}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 16 16">
                        <path fill="#000000" d="M5.23331,0.493645 C6.8801,-0.113331 8.6808,-0.161915 10.3579,0.355379 C11.5179,0.713177 12.5743,1.32796 13.4526,2.14597 L14.2929,1.30564 C14.9229,0.675676 16,1.12184 16,2.01275 L16,6.00002 L12.0127,6.00002 C11.1218,6.00002 10.6757,4.92288 11.3056,4.29291 L12.0372,3.56137 C11.389,2.97184 10.6156,2.52782 9.76845,2.26653 C8.5106,1.87856 7.16008,1.915 5.92498,2.37023 C4.68989,2.82547 3.63877,3.67423 2.93361,4.78573 C2.22844,5.89723 1.90836,7.20978 2.02268,8.52112 C2.13701,9.83246 2.6794,11.0698 3.56627,12.0425 C4.45315,13.0152 5.63528,13.6693 6.93052,13.9039 C8.22576,14.1385 9.56221,13.9407 10.7339,13.3409 C11.9057,12.7412 12.8476,11.7727 13.4147,10.5848 C13.6526,10.0864 14.2495,9.8752 14.748,10.1131 C15.2464,10.351 15.4575,10.948 15.2196,11.4464 C14.4635,13.0302 13.2076,14.3215 11.6453,15.1213 C10.0829,15.921 8.30101,16.1847 6.57402,15.8719 C4.84704,15.559 3.27086,14.687 2.08836,13.39 C0.905861,12.0931 0.182675,10.4433 0.0302394,8.69483 C-0.122195,6.94637 0.304581,5.1963 1.2448,3.7143 C2.18503,2.2323 3.58652,1.10062 5.23331,0.493645 Z"/>
                        </svg>   
                        </div>                     
                    </div> 
                    <div className="progress">
                            <div className="progressBox" style={{"width": `${percentage3 * 190}px`}}></div>
                    </div>
                    </div>
                    : 
                    <div></div>
                }

                </div>
            </div>





            <img src="../images/liana3.png" alt="#" className="liana"/>

            <div className="verticalBlocks">
                <a href={completedTwenty4 === 5 ? "/payingHSK4" : (percentage4 === 1 ? undefined : "/quizHSK4")} className="hskDot">
                    <span className={percentage4 === 1 ? "dotCompletedQuiz" : "dot"} id={percentage4 === 1 ? "dotCompletedQuiz4" : ""}>HSK 4</span>
                </a>

                <div className={isOpenQuiz4 === 1 ? (completedTwenty4 === 5 ? 'notDisplayed' : 'alertDivBlackQuiz') : 'notDisplayed' } id="alertDivBlackQuiz4">
  
                <div className="alertDiv-content">
                The quiz is over, click the button in the extra menu if you want to restart
                </div>
                </div>

                <button className="threeDots" id="id-three-dots4">
                    <div className="blackDot"></div>
                    <div className="blackDot"></div>
                    <div className="blackDot"></div>
                </button>

                <div className="repeatBlockHorizontal">
                {
                    buttonClick4 ? 
                    <div className="repeatBlockButtons">

                    <div className={isOpen4 === 1 ? 'alertDivBlack' : 'notDisplayed' }>
                    
                        <div className="alertDiv-content">
                        Repeat circle is over, click repeat again if you want to restart
                        </div>

                    </div>

                        <div className="repeatBlockButtons2" id="repeatBlockButtons4">
                        <a href={completedRepeating4 === 1 || completedRepeating4 === 3 ? undefined : "/repeatQuizHSK4"} className={completedRepeating4 === 1 || completedRepeating4 === 3 ? "repeatButton2" : "repeatButton"} onClick={completedRepeating4 === 1 ? handleClickAlert4 : null}>
                            Repeat
                        </a>

                        <svg xmlns="http://www.w3.org/2000/svg" className={completedRepeating4 === 1 ? "checkDone" : "notDisplayed"} width="25px" height="25px" viewBox="0 0 24 24" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd" d="M12 21C16.9706 21 21 16.9706 21 12C21 10.1666 20.4518 8.46124 19.5103 7.03891L12.355 14.9893C11.6624 15.7589 10.4968 15.8726 9.66844 15.2513L6.4 12.8C5.95817 12.4686 5.86863 11.8418 6.2 11.4C6.53137 10.9582 7.15817 10.8686 7.6 11.2L10.8684 13.6513L18.214 5.48955C16.5986 3.94717 14.4099 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" fill="green"/>
                        </svg>
                        <div className={percentageRepeat4 != 0 ? "progressRepeat" : "notDisplayed"}>
                            <div className="progressBoxRepeat" style={{"width": `${percentageRepeat4 * 170}px`}}></div>
                        </div>

                        <a href={isPaid4 ? "/listHSK4" : "/listHSK4Words?id=1"} className="wordsList2">
                            Words
                        </a>     
                        </div>   

                        <div className={completedRepeating4 === 1 ? "repeatWordSvgAbsolute2" : "notDisplayed"} onClick={completedRepeating4 === 1 ? handleClickAlertRemoveQuestionsRepeat4 : null}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 16 16">
                        <path fill="#000000" d="M5.23331,0.493645 C6.8801,-0.113331 8.6808,-0.161915 10.3579,0.355379 C11.5179,0.713177 12.5743,1.32796 13.4526,2.14597 L14.2929,1.30564 C14.9229,0.675676 16,1.12184 16,2.01275 L16,6.00002 L12.0127,6.00002 C11.1218,6.00002 10.6757,4.92288 11.3056,4.29291 L12.0372,3.56137 C11.389,2.97184 10.6156,2.52782 9.76845,2.26653 C8.5106,1.87856 7.16008,1.915 5.92498,2.37023 C4.68989,2.82547 3.63877,3.67423 2.93361,4.78573 C2.22844,5.89723 1.90836,7.20978 2.02268,8.52112 C2.13701,9.83246 2.6794,11.0698 3.56627,12.0425 C4.45315,13.0152 5.63528,13.6693 6.93052,13.9039 C8.22576,14.1385 9.56221,13.9407 10.7339,13.3409 C11.9057,12.7412 12.8476,11.7727 13.4147,10.5848 C13.6526,10.0864 14.2495,9.8752 14.748,10.1131 C15.2464,10.351 15.4575,10.948 15.2196,11.4464 C14.4635,13.0302 13.2076,14.3215 11.6453,15.1213 C10.0829,15.921 8.30101,16.1847 6.57402,15.8719 C4.84704,15.559 3.27086,14.687 2.08836,13.39 C0.905861,12.0931 0.182675,10.4433 0.0302394,8.69483 C-0.122195,6.94637 0.304581,5.1963 1.2448,3.7143 C2.18503,2.2323 3.58652,1.10062 5.23331,0.493645 Z"/>
                        </svg>   
                        </div>    
                        <div className={percentage4 === 1 ? "repeatWordSvgAbsolute" : (completedTwenty4 === 5 ? "repeatWordSvgAbsolute" : "notDisplayed")} onClick={percentage4 === 1 || completedTwenty4 === 5 ? handleClickAlertRemoveQuestions4 : null}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 16 16">
                        <path fill="#000000" d="M5.23331,0.493645 C6.8801,-0.113331 8.6808,-0.161915 10.3579,0.355379 C11.5179,0.713177 12.5743,1.32796 13.4526,2.14597 L14.2929,1.30564 C14.9229,0.675676 16,1.12184 16,2.01275 L16,6.00002 L12.0127,6.00002 C11.1218,6.00002 10.6757,4.92288 11.3056,4.29291 L12.0372,3.56137 C11.389,2.97184 10.6156,2.52782 9.76845,2.26653 C8.5106,1.87856 7.16008,1.915 5.92498,2.37023 C4.68989,2.82547 3.63877,3.67423 2.93361,4.78573 C2.22844,5.89723 1.90836,7.20978 2.02268,8.52112 C2.13701,9.83246 2.6794,11.0698 3.56627,12.0425 C4.45315,13.0152 5.63528,13.6693 6.93052,13.9039 C8.22576,14.1385 9.56221,13.9407 10.7339,13.3409 C11.9057,12.7412 12.8476,11.7727 13.4147,10.5848 C13.6526,10.0864 14.2495,9.8752 14.748,10.1131 C15.2464,10.351 15.4575,10.948 15.2196,11.4464 C14.4635,13.0302 13.2076,14.3215 11.6453,15.1213 C10.0829,15.921 8.30101,16.1847 6.57402,15.8719 C4.84704,15.559 3.27086,14.687 2.08836,13.39 C0.905861,12.0931 0.182675,10.4433 0.0302394,8.69483 C-0.122195,6.94637 0.304581,5.1963 1.2448,3.7143 C2.18503,2.2323 3.58652,1.10062 5.23331,0.493645 Z"/>
                        </svg>   
                        </div>      

                    </div> 
                    : 
                    <div className="emptyBox"></div>
                }

                <div className="progress">
                    <div className="progressBox" style={{"width": `${percentage4 * 190}px`}}></div>
                </div>
                </div>

                <div className="repeatBlockResponsive">
                {
                    buttonClick4 ? 
                    <div className="verticalBlocksResponsiveRepeatBlock">
                    <div className="repeatBlockButtons">
                    <div className={isOpen4 === 1 ? 'alertDivBlack' : 'notDisplayed' }>
  
                    <div className="alertDiv-content">
                    Repeat circle is over, click repeat again if you want to restart
                    </div>
                    </div>
                        <div className="repeatBlockButtons2" id="repeatBlockButtons4">
                        <a href={completedRepeating4 === 1 || completedRepeating4 === 3 ? undefined : "/repeatQuizHSK4"} className={completedRepeating4 === 1 || completedRepeating4 === 3 ? "repeatButton2" : "repeatButton"} onClick={completedRepeating4 === 1 ? handleClickAlert4 : null}>
                            Repeat
                        </a>
                        <svg xmlns="http://www.w3.org/2000/svg" className={completedRepeating4 === 1 ? "checkDone" : "notDisplayed"} width="25px" height="25px" viewBox="0 0 24 24" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd" d="M12 21C16.9706 21 21 16.9706 21 12C21 10.1666 20.4518 8.46124 19.5103 7.03891L12.355 14.9893C11.6624 15.7589 10.4968 15.8726 9.66844 15.2513L6.4 12.8C5.95817 12.4686 5.86863 11.8418 6.2 11.4C6.53137 10.9582 7.15817 10.8686 7.6 11.2L10.8684 13.6513L18.214 5.48955C16.5986 3.94717 14.4099 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" fill="green"/>
                        </svg>
                        <div className={percentageRepeat4 != 0 ? "progressRepeat" : "notDisplayed"}>
                            <div className="progressBoxRepeat" style={{"width": `${percentageRepeat4 * 170}px`}}></div>
                        </div>
                        <a href={isPaid4 ? "/listHSK4" : "/listHSK4Words?id=1"} className="wordsList">
                            Words
                        </a>     
                        </div>  
                        <div className={completedRepeating4 === 1 ? "repeatWordSvgAbsolute2" : "notDisplayed"} onClick={completedRepeating4 === 1 ? handleClickAlertRemoveQuestionsRepeat4 : null}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 16 16">
                        <path fill="#000000" d="M5.23331,0.493645 C6.8801,-0.113331 8.6808,-0.161915 10.3579,0.355379 C11.5179,0.713177 12.5743,1.32796 13.4526,2.14597 L14.2929,1.30564 C14.9229,0.675676 16,1.12184 16,2.01275 L16,6.00002 L12.0127,6.00002 C11.1218,6.00002 10.6757,4.92288 11.3056,4.29291 L12.0372,3.56137 C11.389,2.97184 10.6156,2.52782 9.76845,2.26653 C8.5106,1.87856 7.16008,1.915 5.92498,2.37023 C4.68989,2.82547 3.63877,3.67423 2.93361,4.78573 C2.22844,5.89723 1.90836,7.20978 2.02268,8.52112 C2.13701,9.83246 2.6794,11.0698 3.56627,12.0425 C4.45315,13.0152 5.63528,13.6693 6.93052,13.9039 C8.22576,14.1385 9.56221,13.9407 10.7339,13.3409 C11.9057,12.7412 12.8476,11.7727 13.4147,10.5848 C13.6526,10.0864 14.2495,9.8752 14.748,10.1131 C15.2464,10.351 15.4575,10.948 15.2196,11.4464 C14.4635,13.0302 13.2076,14.3215 11.6453,15.1213 C10.0829,15.921 8.30101,16.1847 6.57402,15.8719 C4.84704,15.559 3.27086,14.687 2.08836,13.39 C0.905861,12.0931 0.182675,10.4433 0.0302394,8.69483 C-0.122195,6.94637 0.304581,5.1963 1.2448,3.7143 C2.18503,2.2323 3.58652,1.10062 5.23331,0.493645 Z"/>
                        </svg>   
                        </div>    
                        <div className={percentage4 === 1 ? "repeatWordSvgAbsolute" : (completedTwenty4 === 5 ? "repeatWordSvgAbsolute" : "notDisplayed")} onClick={percentage4 === 1 || completedTwenty4 === 5 ? handleClickAlertRemoveQuestions4 : null}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 16 16">
                        <path fill="#000000" d="M5.23331,0.493645 C6.8801,-0.113331 8.6808,-0.161915 10.3579,0.355379 C11.5179,0.713177 12.5743,1.32796 13.4526,2.14597 L14.2929,1.30564 C14.9229,0.675676 16,1.12184 16,2.01275 L16,6.00002 L12.0127,6.00002 C11.1218,6.00002 10.6757,4.92288 11.3056,4.29291 L12.0372,3.56137 C11.389,2.97184 10.6156,2.52782 9.76845,2.26653 C8.5106,1.87856 7.16008,1.915 5.92498,2.37023 C4.68989,2.82547 3.63877,3.67423 2.93361,4.78573 C2.22844,5.89723 1.90836,7.20978 2.02268,8.52112 C2.13701,9.83246 2.6794,11.0698 3.56627,12.0425 C4.45315,13.0152 5.63528,13.6693 6.93052,13.9039 C8.22576,14.1385 9.56221,13.9407 10.7339,13.3409 C11.9057,12.7412 12.8476,11.7727 13.4147,10.5848 C13.6526,10.0864 14.2495,9.8752 14.748,10.1131 C15.2464,10.351 15.4575,10.948 15.2196,11.4464 C14.4635,13.0302 13.2076,14.3215 11.6453,15.1213 C10.0829,15.921 8.30101,16.1847 6.57402,15.8719 C4.84704,15.559 3.27086,14.687 2.08836,13.39 C0.905861,12.0931 0.182675,10.4433 0.0302394,8.69483 C-0.122195,6.94637 0.304581,5.1963 1.2448,3.7143 C2.18503,2.2323 3.58652,1.10062 5.23331,0.493645 Z"/>
                        </svg>   
                        </div>                     
                    </div> 
                    <div className="progress">
                            <div className="progressBox" style={{"width": `${percentage4 * 190}px`}}></div>
                    </div>
                    </div>
                    : 
                    <div></div>
                }

                </div>
            </div>







            <img src="../images/liana3.png" alt="#" className="liana"/>

            <div className="verticalBlocks">
                <a href={completedTwenty5 === 5 ? "/payingHSK5" : (percentage5 === 1 ? undefined : "/quizHSK5")} className="hskDot">
                    <span className={percentage5 === 1 ? "dotCompletedQuiz" : "dot"} id={percentage5 === 1 ? "dotCompletedQuiz5" : ""}>HSK 5</span>
                </a>

                <div className={isOpenQuiz5 === 1 ? (completedTwenty5 === 5 ? 'notDisplayed' : 'alertDivBlackQuiz') : 'notDisplayed' } id="alertDivBlackQuiz5">
  
                <div className="alertDiv-content">
                The quiz is over, click the button in the extra menu if you want to restart
                </div>
                </div>

                <button className="threeDots" id="id-three-dots5">
                    <div className="blackDot"></div>
                    <div className="blackDot"></div>
                    <div className="blackDot"></div>
                </button>

                <div className="repeatBlockHorizontal">
                {
                    buttonClick5 ? 
                    <div className="repeatBlockButtons">

                    <div className={isOpen5 === 1 ? 'alertDivBlack' : 'notDisplayed' }>
                    
                        <div className="alertDiv-content">
                        Repeat circle is over, click repeat again if you want to restart
                        </div>

                    </div>

                        <div className="repeatBlockButtons2" id="repeatBlockButtons5">
                        <a href={completedRepeating5 === 1 || completedRepeating5 === 3 ? undefined : "/repeatQuizHSK5"} className={completedRepeating5 === 1 || completedRepeating5 === 3 ? "repeatButton2" : "repeatButton"} onClick={completedRepeating5 === 1 ? handleClickAlert5 : null}>
                            Repeat
                        </a>

                        <svg xmlns="http://www.w3.org/2000/svg" className={completedRepeating5 === 1 ? "checkDone" : "notDisplayed"} width="25px" height="25px" viewBox="0 0 24 24" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd" d="M12 21C16.9706 21 21 16.9706 21 12C21 10.1666 20.4518 8.46124 19.5103 7.03891L12.355 14.9893C11.6624 15.7589 10.4968 15.8726 9.66844 15.2513L6.4 12.8C5.95817 12.4686 5.86863 11.8418 6.2 11.4C6.53137 10.9582 7.15817 10.8686 7.6 11.2L10.8684 13.6513L18.214 5.48955C16.5986 3.94717 14.4099 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" fill="green"/>
                        </svg>
                        <div className={percentageRepeat5 != 0 ? "progressRepeat" : "notDisplayed"}>
                            <div className="progressBoxRepeat" style={{"width": `${percentageRepeat5 * 170}px`}}></div>
                        </div>

                        <a href={isPaid5 ? "/listHSK5" : "/listHSK5Words?id=1"} className="wordsList2">
                            Words
                        </a>     
                        </div>   

                        <div className={completedRepeating5 === 1 ? "repeatWordSvgAbsolute2" : "notDisplayed"} onClick={completedRepeating5 === 1 ? handleClickAlertRemoveQuestionsRepeat5 : null}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 16 16">
                        <path fill="#000000" d="M5.23331,0.493645 C6.8801,-0.113331 8.6808,-0.161915 10.3579,0.355379 C11.5179,0.713177 12.5743,1.32796 13.4526,2.14597 L14.2929,1.30564 C14.9229,0.675676 16,1.12184 16,2.01275 L16,6.00002 L12.0127,6.00002 C11.1218,6.00002 10.6757,4.92288 11.3056,4.29291 L12.0372,3.56137 C11.389,2.97184 10.6156,2.52782 9.76845,2.26653 C8.5106,1.87856 7.16008,1.915 5.92498,2.37023 C4.68989,2.82547 3.63877,3.67423 2.93361,4.78573 C2.22844,5.89723 1.90836,7.20978 2.02268,8.52112 C2.13701,9.83246 2.6794,11.0698 3.56627,12.0425 C4.45315,13.0152 5.63528,13.6693 6.93052,13.9039 C8.22576,14.1385 9.56221,13.9407 10.7339,13.3409 C11.9057,12.7412 12.8476,11.7727 13.4147,10.5848 C13.6526,10.0864 14.2495,9.8752 14.748,10.1131 C15.2464,10.351 15.4575,10.948 15.2196,11.4464 C14.4635,13.0302 13.2076,14.3215 11.6453,15.1213 C10.0829,15.921 8.30101,16.1847 6.57402,15.8719 C4.84704,15.559 3.27086,14.687 2.08836,13.39 C0.905861,12.0931 0.182675,10.4433 0.0302394,8.69483 C-0.122195,6.94637 0.304581,5.1963 1.2448,3.7143 C2.18503,2.2323 3.58652,1.10062 5.23331,0.493645 Z"/>
                        </svg>   
                        </div>    
                        <div className={percentage5 === 1 ? "repeatWordSvgAbsolute" : (completedTwenty5 === 5 ? "repeatWordSvgAbsolute" : "notDisplayed")} onClick={percentage5 === 1 || completedTwenty5 === 5 ? handleClickAlertRemoveQuestions5 : null}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 16 16">
                        <path fill="#000000" d="M5.23331,0.493645 C6.8801,-0.113331 8.6808,-0.161915 10.3579,0.355379 C11.5179,0.713177 12.5743,1.32796 13.4526,2.14597 L14.2929,1.30564 C14.9229,0.675676 16,1.12184 16,2.01275 L16,6.00002 L12.0127,6.00002 C11.1218,6.00002 10.6757,4.92288 11.3056,4.29291 L12.0372,3.56137 C11.389,2.97184 10.6156,2.52782 9.76845,2.26653 C8.5106,1.87856 7.16008,1.915 5.92498,2.37023 C4.68989,2.82547 3.63877,3.67423 2.93361,4.78573 C2.22844,5.89723 1.90836,7.20978 2.02268,8.52112 C2.13701,9.83246 2.6794,11.0698 3.56627,12.0425 C4.45315,13.0152 5.63528,13.6693 6.93052,13.9039 C8.22576,14.1385 9.56221,13.9407 10.7339,13.3409 C11.9057,12.7412 12.8476,11.7727 13.4147,10.5848 C13.6526,10.0864 14.2495,9.8752 14.748,10.1131 C15.2464,10.351 15.4575,10.948 15.2196,11.4464 C14.4635,13.0302 13.2076,14.3215 11.6453,15.1213 C10.0829,15.921 8.30101,16.1847 6.57402,15.8719 C4.84704,15.559 3.27086,14.687 2.08836,13.39 C0.905861,12.0931 0.182675,10.4433 0.0302394,8.69483 C-0.122195,6.94637 0.304581,5.1963 1.2448,3.7143 C2.18503,2.2323 3.58652,1.10062 5.23331,0.493645 Z"/>
                        </svg>   
                        </div>      

                    </div> 
                    : 
                    <div className="emptyBox"></div>
                }

                <div className="progress">
                    <div className="progressBox" style={{"width": `${percentage5 * 190}px`}}></div>
                </div>
                </div>

                <div className="repeatBlockResponsive">
                {
                    buttonClick5 ? 
                    <div className="verticalBlocksResponsiveRepeatBlock">
                    <div className="repeatBlockButtons">
                    <div className={isOpen5 === 1 ? 'alertDivBlack' : 'notDisplayed' }>
  
                    <div className="alertDiv-content">
                    Repeat circle is over, click repeat again if you want to restart
                    </div>
                    </div>
                        <div className="repeatBlockButtons2" id="repeatBlockButtons5">
                        <a href={completedRepeating5 === 1 || completedRepeating5 === 3 ? undefined : "/repeatQuizHSK5"} className={completedRepeating5 === 1 || completedRepeating5 === 3 ? "repeatButton2" : "repeatButton"} onClick={completedRepeating5 === 1 ? handleClickAlert5 : null}>
                            Repeat
                        </a>
                        <svg xmlns="http://www.w3.org/2000/svg" className={completedRepeating5 === 1 ? "checkDone" : "notDisplayed"} width="25px" height="25px" viewBox="0 0 24 24" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd" d="M12 21C16.9706 21 21 16.9706 21 12C21 10.1666 20.4518 8.46124 19.5103 7.03891L12.355 14.9893C11.6624 15.7589 10.4968 15.8726 9.66844 15.2513L6.4 12.8C5.95817 12.4686 5.86863 11.8418 6.2 11.4C6.53137 10.9582 7.15817 10.8686 7.6 11.2L10.8684 13.6513L18.214 5.48955C16.5986 3.94717 14.4099 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" fill="green"/>
                        </svg>
                        <div className={percentageRepeat5 != 0 ? "progressRepeat" : "notDisplayed"}>
                            <div className="progressBoxRepeat" style={{"width": `${percentageRepeat5 * 170}px`}}></div>
                        </div>
                        <a href={isPaid5 ? "/listHSK5" : "/listHSK5Words?id=1"} className="wordsList">
                            Words
                        </a>     
                        </div>  
                        <div className={completedRepeating5 === 1 ? "repeatWordSvgAbsolute2" : "notDisplayed"} onClick={completedRepeating5 === 1 ? handleClickAlertRemoveQuestionsRepeat : null}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 16 16">
                        <path fill="#000000" d="M5.23331,0.493645 C6.8801,-0.113331 8.6808,-0.161915 10.3579,0.355379 C11.5179,0.713177 12.5743,1.32796 13.4526,2.14597 L14.2929,1.30564 C14.9229,0.675676 16,1.12184 16,2.01275 L16,6.00002 L12.0127,6.00002 C11.1218,6.00002 10.6757,4.92288 11.3056,4.29291 L12.0372,3.56137 C11.389,2.97184 10.6156,2.52782 9.76845,2.26653 C8.5106,1.87856 7.16008,1.915 5.92498,2.37023 C4.68989,2.82547 3.63877,3.67423 2.93361,4.78573 C2.22844,5.89723 1.90836,7.20978 2.02268,8.52112 C2.13701,9.83246 2.6794,11.0698 3.56627,12.0425 C4.45315,13.0152 5.63528,13.6693 6.93052,13.9039 C8.22576,14.1385 9.56221,13.9407 10.7339,13.3409 C11.9057,12.7412 12.8476,11.7727 13.4147,10.5848 C13.6526,10.0864 14.2495,9.8752 14.748,10.1131 C15.2464,10.351 15.4575,10.948 15.2196,11.4464 C14.4635,13.0302 13.2076,14.3215 11.6453,15.1213 C10.0829,15.921 8.30101,16.1847 6.57402,15.8719 C4.84704,15.559 3.27086,14.687 2.08836,13.39 C0.905861,12.0931 0.182675,10.4433 0.0302394,8.69483 C-0.122195,6.94637 0.304581,5.1963 1.2448,3.7143 C2.18503,2.2323 3.58652,1.10062 5.23331,0.493645 Z"/>
                        </svg>   
                        </div>    
                        <div className={percentage5 === 1 ? "repeatWordSvgAbsolute" : (completedTwenty5 === 5 ? "repeatWordSvgAbsolute" : "notDisplayed")} onClick={percentage5 === 1 || completedTwenty5 === 5 ? handleClickAlertRemoveQuestions : null}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 16 16">
                        <path fill="#000000" d="M5.23331,0.493645 C6.8801,-0.113331 8.6808,-0.161915 10.3579,0.355379 C11.5179,0.713177 12.5743,1.32796 13.4526,2.14597 L14.2929,1.30564 C14.9229,0.675676 16,1.12184 16,2.01275 L16,6.00002 L12.0127,6.00002 C11.1218,6.00002 10.6757,4.92288 11.3056,4.29291 L12.0372,3.56137 C11.389,2.97184 10.6156,2.52782 9.76845,2.26653 C8.5106,1.87856 7.16008,1.915 5.92498,2.37023 C4.68989,2.82547 3.63877,3.67423 2.93361,4.78573 C2.22844,5.89723 1.90836,7.20978 2.02268,8.52112 C2.13701,9.83246 2.6794,11.0698 3.56627,12.0425 C4.45315,13.0152 5.63528,13.6693 6.93052,13.9039 C8.22576,14.1385 9.56221,13.9407 10.7339,13.3409 C11.9057,12.7412 12.8476,11.7727 13.4147,10.5848 C13.6526,10.0864 14.2495,9.8752 14.748,10.1131 C15.2464,10.351 15.4575,10.948 15.2196,11.4464 C14.4635,13.0302 13.2076,14.3215 11.6453,15.1213 C10.0829,15.921 8.30101,16.1847 6.57402,15.8719 C4.84704,15.559 3.27086,14.687 2.08836,13.39 C0.905861,12.0931 0.182675,10.4433 0.0302394,8.69483 C-0.122195,6.94637 0.304581,5.1963 1.2448,3.7143 C2.18503,2.2323 3.58652,1.10062 5.23331,0.493645 Z"/>
                        </svg>   
                        </div>                     
                    </div> 
                    <div className="progress">
                            <div className="progressBox" style={{"width": `${percentage5 * 190}px`}}></div>
                    </div>
                    </div>
                    : 
                    <div></div>
                }

                </div>
            </div>




            <img src="../images/liana3.png" alt="#" className="liana"/>

            <div className="verticalBlocksLast">
                <a className="noLink">
                    <span className="dot" id={hsk6Message === 'exists' ? "" : "askQuestion6"}>HSK 6</span>
                </a>

                <button className="threeDots" id="id-three-dots6">
                    <div className="blackDot"></div>
                    <div className="blackDot"></div>
                    <div className="blackDot"></div>
                </button>

                <div className="repeatBlockHorizontal">
                {
                    buttonClick6 ? 
                    <div className="repeatBlockButtons">
                        <div className="repeatBlockButtons2" id="repeatBlockButtons6">
                        <a className="repeatButton2">
                            Repeat
                        </a>
                        <a className="wordsList2">
                            Words
                        </a>     
                        </div>                  
                    </div> 
                    : 
                    <div className="emptyBox"></div>
                }

                <div className="progress">
                    <div className="progressBox" style={{"width": `${0}px`}}></div>
                </div>
                </div>

                <div className="repeatBlockResponsive">
                {
                    buttonClick6 ? 
                    <div className="verticalBlocksResponsiveRepeatBlock">
                    <div className="repeatBlockButtons">
                        <div className="repeatBlockButtons2" id="repeatBlockButtons6">
                        <a className="repeatButton2">
                            Repeat
                        </a>
                        <a className="wordsList2">
                            Words
                        </a>     
                        </div>                  
                    </div> 
                    <div className="progress">
                        <div className="progressBox" style={{"width": `${0}px`}}></div>
                    </div>
                    </div>
                    : 
                    <div></div>
                }

                </div>

            </div>
            <div className={askingBox === 0 ? "askingBoxNone" : "askingBox"}>
                <p className="pRemove">Do you want to repeat quiz again?</p>
                <div className="answerRemove">
                    <button className="yesAnswer" onClick={() => {restartQuizRepeat()}}>Yes</button>
                    <button className="noAnswer" onClick={() => {removeChatFunctionOff()}}>No</button>
                </div>
              </div>
              <div className={askingBoxQuiz === 0 ? "askingBoxNone" : "askingBox"}>
                <p className="pRemovePage">Do you want to restart the quiz?</p>
                <p className="pRemovePageDescriptionQuiz">Note: All the data will be removed</p>
                <div className="answerRemove">
                    <button className="yesAnswer" onClick={() => {restartQuiz()}}>Yes</button>
                    <button className="noAnswer" onClick={() => {removeChatFunctionOffQuiz()}}>No</button>
                </div>
              </div>

              <div className={askingBoxSendMessage === 0 ? "askingBoxNone" : "askingBox"} id="askingBoxSendMessage">
                <p className="pRemove">Do you want to get email when HSK{hsk} is available?</p>
                <div className="answerRemove">
                    <button className="yesAnswer" onClick={() => {sendMessageInfoYes(hsk)}}>Yes</button>
                    <button className="noAnswer" onClick={() => {sendMessageInfoNot(hsk)}}>No</button>
                </div>
              </div>




              <div className={askingBox2 === 0 ? "askingBoxNone" : "askingBox"}>
                <p className="pRemove">Do you want to repeat quiz again?</p>
                <div className="answerRemove">
                    <button className="yesAnswer" onClick={() => {restartQuizRepeat2()}}>Yes</button>
                    <button className="noAnswer" onClick={() => {removeChatFunctionOff2()}}>No</button>
                </div>
              </div>

              <div className={askingBoxQuiz2 === 0 ? "askingBoxNone" : "askingBox"}>
                <p className="pRemovePage">Do you want to restart the quiz?</p>
                <p className="pRemovePageDescriptionQuiz">Note: All the data will be removed</p>
                <div className="answerRemove">
                    <button className="yesAnswer" onClick={() => {restartQuiz2()}}>Yes</button>
                    <button className="noAnswer" onClick={() => {removeChatFunctionOffQuiz2()}}>No</button>
                </div>
              </div>

              <div className={askingBox3 === 0 ? "askingBoxNone" : "askingBox"}>
                <p className="pRemove">Do you want to repeat quiz again?</p>
                <div className="answerRemove">
                    <button className="yesAnswer" onClick={() => {restartQuizRepeat3()}}>Yes</button>
                    <button className="noAnswer" onClick={() => {removeChatFunctionOff3()}}>No</button>
                </div>
              </div>
              <div className={askingBoxQuiz3 === 0 ? "askingBoxNone" : "askingBox"}>
                <p className="pRemovePage">Do you want to restart the quiz?</p>
                <p className="pRemovePageDescriptionQuiz">Note: All the data will be removed</p>
                <div className="answerRemove">
                    <button className="yesAnswer" onClick={() => {restartQuiz3()}}>Yes</button>
                    <button className="noAnswer" onClick={() => {removeChatFunctionOffQuiz3()}}>No</button>
                </div>
              </div>

              <div className={askingBox4 === 0 ? "askingBoxNone" : "askingBox"}>
                <p className="pRemove">Do you want to repeat quiz again?</p>
                <div className="answerRemove">
                    <button className="yesAnswer" onClick={() => {restartQuizRepeat4()}}>Yes</button>
                    <button className="noAnswer" onClick={() => {removeChatFunctionOff4()}}>No</button>
                </div>
              </div>
              <div className={askingBoxQuiz4 === 0 ? "askingBoxNone" : "askingBox"}>
                <p className="pRemovePage">Do you want to restart the quiz?</p>
                <p className="pRemovePageDescriptionQuiz">Note: All the data will be removed</p>
                <div className="answerRemove">
                    <button className="yesAnswer" onClick={() => {restartQuiz4()}}>Yes</button>
                    <button className="noAnswer" onClick={() => {removeChatFunctionOffQuiz4()}}>No</button>
                </div>
              </div>

              <div className={askingBox5 === 0 ? "askingBoxNone" : "askingBox"}>
                <p className="pRemove">Do you want to repeat quiz again?</p>
                <div className="answerRemove">
                    <button className="yesAnswer" onClick={() => {restartQuizRepeat5()}}>Yes</button>
                    <button className="noAnswer" onClick={() => {removeChatFunctionOff5()}}>No</button>
                </div>
              </div>
              <div className={askingBoxQuiz5 === 0 ? "askingBoxNone" : "askingBox"}>
                <p className="pRemovePage">Do you want to restart the quiz?</p>
                <p className="pRemovePageDescriptionQuiz">Note: All the data will be removed</p>
                <div className="answerRemove">
                    <button className="yesAnswer" onClick={() => {restartQuiz5()}}>Yes</button>
                    <button className="noAnswer" onClick={() => {removeChatFunctionOffQuiz5()}}>No</button>
                </div>
              </div>


        </div> 
    </div>
    )
}